import React from "react";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../css/global.css";
import "../css/about.css";

const Artwork = () => {
  const { instagram } = useSiteMetadata();
  return (
    <Layout>
      <SEO title="Artwork" />
      <section className="intro">
        <h3>Gallery</h3>
        <p>
          Gallery coming soon. In the meantime, check out the &nbsp;
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            Instagram account
          </a>
          &nbsp; for ongoing updates and new images.
        </p>
        <h3>Latest work</h3>
        <p>
          The latest series involves laying down watercolours in thinned,
          translucent layers around a circular void, building up to a
          multi-layered abstract design. These layers represent the multitude of
          decisions made, and paths chosen, that combine to create a snapshot of
          a life at a specific point in time. Combined with this concept is a
          long held fascination with the Many Worlds Theory, which posits that
          quantum effects cause our universe to infinitely branch off into
          others. This theory can appeal to anyone who has looked back over
          their life, and wondered, whether with regret or simply idle
          curiosity, how things might exist if a different path had been chosen.
          With this series then, each work is revealed as a snapshot of
          the&nbsp;
          <em>same</em>&nbsp;life, but diverged, with differing choices and
          events culminating in a unique outcome.
        </p>
      </section>
    </Layout>
  );
};

export default Artwork;
